@use 'variables' as *;

@mixin phone {
  @media (max-width: #{$breakpoint-tablet - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$breakpoint-tablet}) and (max-width: #{$breakpoint-desktop - 1px}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$breakpoint-desktop - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$breakpoint-desktop}) {
    @content;
  }
}

@mixin media($keys...) {
  @each $key in $keys {
    @if ($key == phone) {
      @include phone {
        @content;
      }
    } @else if ($key == tablet) {
      @include tablet {
        @content;
      }
    } @else if ($key == mobile) {
      @include mobile {
        @content;
      }
    } @else if ($key == desktop) {
      @include desktop {
        @content;
      }
    }
  }
}

@mixin text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin line-clamp($line-count, $line-height) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $line-count;

  max-height: $line-height * $line-count;
  line-height: $line-height;
  overflow: hidden;
}

@mixin reset-button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;

  /* Remove excess padding and border in Firefox 4+ */
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}
