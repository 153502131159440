$text-1: #282828;
$text-2: #999999;
$text-3: #cccccc;
$text-4: #e6e6e6;

$text-point-1: #bdd700;
$text-point-2: #55e9e9;

$bg-web: #f5f5f5;
$bg-mob: #f5f5f7;

$line-1: #282828;
$line-2: #e6e6e6;

$point-1: #e0ff00;
$point-2: #5effff;

$info: #0047ff;
$warning: #ff0000;
