/* You can add global styles to this file, and also import other style files */
@import 'common/reset';
@import 'common/font';

@import 'sui';

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

:root {
  --buk-safe-area-inset-top: env(safe-area-inset-top, 0);
  --buk-safe-area-inset-right: env(safe-area-inset-right, 0);
  --buk-safe-area-inset-bottom: env(safe-area-inset-bottom, 0);
  --buk-safe-area-inset-left: env(safe-area-inset-left, 0);
}
