@use 'common/mixins';

.sui-alert-backdrop {
  backdrop-filter: blur(4px);

  @include mixins.desktop {
    background: #2828284d;
  }

  @include mixins.mobile {
    background: #282828b2;
  }
}

.sui-dialog-backdrop {
  background: transparent;

  &.sui-dialog-backdrop-dimmed {
    backdrop-filter: blur(4px);
    background: #28282880;
  }
}
