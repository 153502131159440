@use 'sass:math';

@use 'common/mixins';
@use 'common/palette';

:root {
  // 커버 너비, 높이는 비율 유지됨
  --sui-book-cover-width: 178px;

  // 흰 박스 영역 크기
  --sui-book-bg-box-width: 373px;
  --sui-book-bg-box-height: 380px;

  @include mixins.mobile {
    --sui-book-bg-box-width: 375px;
    --sui-book-bg-box-height: 446px;
  }
}

sui-book {
  display: inline-block;
  overflow: hidden;
  color: palette.$text-1;
}

.sui-book__cover-container {
  display: block;
  width: auto;
  position: relative;
}

.sui-book-cover {
  display: block;
  width: var(--sui-book-cover-width);
  height: auto;
  max-height: calc(#{math.div(266, 178)} * var(--sui-book-cover-width));
  box-shadow: 0px 4px 4px 0px #2828281a;
}

sui-book-cover-tag {
  position: absolute;
  top: 0;
  right: 0;
}

sui-book-title,
sui-book-author {
  display: block;
  overflow-wrap: break-word;

  @include mixins.desktop {
    width: 234px;
  }

  @include mixins.mobile {
    max-width: 200px;
    margin-left: 10px;
    margin-right: 10px;
    text-align: center;
  }
}

sui-book.sui-book--layout-desktop {
  sui-book-title,
  sui-book-author {
    width: 234px;

    max-width: initial;
    margin-left: initial;
    margin-right: initial;
    text-align: initial;
  }
}

sui-book.sui-book--layout-mobile {
  sui-book-title,
  sui-book-author {
    width: initial;

    max-width: 200px;
    margin-left: 10px;
    margin-right: 10px;
    text-align: center;
  }
}

sui-book-title {
  margin-top: 20px;

  font-size: 18px;
  font-weight: 600;
  line-height: 24px;

  @include mixins.line-clamp(2, 24px);

  @include mixins.mobile {
    font-weight: 700;
  }
}

sui-book.sui-book--layout-desktop {
  sui-book-title {
    font-weight: 600;
  }
}

sui-book.sui-book--layout-mobile {
  sui-book-title {
    font-weight: 700;
  }
}

sui-book-author {
  margin-top: 4px;

  font-size: 15px;
  font-weight: 500;
  line-height: 18px;

  color: palette.$text-2;

  @include mixins.text-ellipsis;
}

@include mixins.desktop {
  .sui-book__cover-box {
    background: #ffffff66;
    position: relative;

    width: var(--sui-book-bg-box-width);
    height: var(--sui-book-bg-box-height);

    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@include mixins.mobile {
  .sui-book__box {
    background: #ffffff66;
    position: relative;

    width: var(--sui-book-bg-box-width);
    height: var(--sui-book-bg-box-height);

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}

sui-book.sui-book--layout-desktop {
  .sui-book__cover-box {
    background: #ffffff66;
    position: relative;

    width: var(--sui-book-bg-box-width);
    height: var(--sui-book-bg-box-height);

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sui-book__box {
    background: initial;
    position: initial;

    width: initial;
    height: initial;

    display: initial;
    align-items: initial;
    flex-direction: initial;
    justify-content: initial;
  }
}

sui-book.sui-book--layout-mobile {
  .sui-book__cover-box {
    background: initial;
    position: initial;

    width: initial;
    height: initial;

    display: initial;
    align-items: initial;
    justify-content: initial;
  }

  .sui-book__box {
    background: #ffffff66;
    position: relative;

    width: var(--sui-book-bg-box-width);
    height: var(--sui-book-bg-box-height);

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}

.sui-book__tail-tags {
  position: absolute;
  bottom: 0;
  left: 0;
  background: white;

  font-size: 12px;
  font-weight: 500;
  line-height: 18px;

  border-top-right-radius: 10px;

  sui-book-tail-tag {
    display: inline-block;
    padding: 6px 10px;
    position: relative;

    + sui-book-tail-tag {
      &::before {
        position: absolute;
        top: 7px;
        bottom: 7px;
        left: 0;

        content: ' ';
        display: inline-block;
        width: 1px;
        background: #cccccccc;
      }
    }
  }

  .sui-book__tail-tags-tail {
    position: absolute;
    bottom: 100%;
    left: 0;

    &:first-child {
      display: none;
    }
  }
}

.sui-book__tags {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
}

sui-book-tag {
  min-width: 0;
  height: 36px;
  padding: 0 14px;
  display: flex;
  align-items: center;
  position: relative;

  font-size: 13px;
  font-weight: 500;
  line-height: 15px;

  border-bottom-right-radius: 10px;
  white-space: nowrap;

  @include mixins.mobile {
    height: 30px;
    padding: 0 12px;

    font-size: 11px;
    line-height: 13px;
  }

  .sui-book-tag--tail {
    position: absolute;
    left: -10px;
    bottom: 0;
  }

  sui-icon {
    font-size: 20px;
    margin-right: 6px;

    @include mixins.mobile {
      font-size: 16px;
      margin-right: 5px;
    }
  }

  &.sui-book-tag--theme-yellowgreen {
    background: #e0ff00;

    .sui-book-tag--tail path {
      fill: #e0ff00;
    }
  }

  &.sui-book-tag--theme-green {
    background: #74ffab;

    .sui-book-tag--tail path {
      fill: #74ffab;
    }
  }

  &.sui-book-tag--theme-gray {
    background: #e6e6e6;

    .sui-book-tag--tail path {
      fill: #e6e6e6;
    }
  }
}

sui-book.sui-book--layout-desktop {
  sui-book-tag {
    height: 36px;
    padding: 0 14px;

    font-size: 13px;
    line-height: 15px;

    sui-icon {
      font-size: 20px;
      margin-right: 6px;
    }
  }
}

sui-book.sui-book--layout-mobile {
  sui-book-tag {
    height: 30px;
    padding: 0 12px;

    font-size: 11px;
    line-height: 13px;

    sui-icon {
      font-size: 16px;
      margin-right: 5px;
    }
  }
}

sui-book {
  .sui-book__overlay,
  .sui-book__cover-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;

    > * {
      pointer-events: auto;
    }
  }

  // 유틸리티 클래스

  .sui-book--mobile {
    @include mixins.desktop {
      display: none;
    }
  }

  .sui-book--desktop {
    @include mixins.mobile {
      display: none;
    }
  }
}
