@use 'common/palette';
@use 'common/mixins';

.sui-button {
  @include mixins.reset-button;

  border: none;
  padding: 0;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  border-top-right-radius: 10px;

  color: palette.$text-1;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;

  &:not([disabled]) {
    cursor: pointer;
  }

  &[disabled] {
    background: palette.$line-2;
    border-color: palette.$line-2;
    color: palette.$text-2;
  }

  sui-icon {
    margin-left: 4px;
    margin-right: -5px;
    font-size: 24px;
  }
}

.sui-button {
  &.large {
    padding: 15px 0;
    min-width: 194px;

    @include mixins.mobile {
      font-size: 15px;
      min-width: 267px;
      padding: 16px 0;
    }
  }

  &.normal {
    padding: 11px 0;
    min-width: 140px;

    @include mixins.mobile {
      min-width: 160px;
      padding: 16px 0;
    }
  }

  &.small {
    @include mixins.mobile {
      min-width: 160px;
      padding: 9px 0;
    }
  }

  &.withIcon {
    padding: 10px 20px;
  }
}

@mixin button($type) {
  @if $type == medium48 {
    height: 48px;
    padding: 0 13px;
    min-width: 194px;
  } @else if $type == medium40 {
    height: 40px;
    padding: 0 9px;
    min-width: 140px;
  } @else if $type == medium44 {
    height: 44px;
    padding: 0 14px;
    min-width: 134px;
  } @else if $type == medium54 {
    height: 54px;
    padding: 0 16px;
    min-width: 160px;
  } @else if $type == large {
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;

    height: 54px;
    padding: 0 16px;
    min-width: 267px;

    sui-icon {
      margin-left: 9px;
    }
  } @else if $type == mini {
    font-size: 10px;
    line-height: 15px;

    height: 27px;
    padding: 0 13px;

    sui-icon {
      margin-left: 4px;
      margin-right: -4px;
      font-size: 15px;
    }
  }
}

$mediaList: desktop, mobile, tablet, phone;
$types: medium48, medium40, medium44, medium54, large, mini;

@mixin responsive-button($media) {
  @each $type in $types {
    .sui-button--#{$media}--#{$type} {
      @include mixins.media($media) {
        @include button($type);
      }
    }
  }
}

@each $media in $mediaList {
  @include responsive-button($media);
}

@each $type in $types {
  .sui-button--#{$type} {
    @include button($type);
  }
}
